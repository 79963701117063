import { isMobileOnly } from 'react-device-detect';

import './offer.css';

import customers from '../../assets/customers.png';
import python from '../../assets/tech/python.svg';
import aws from '../../assets/tech/aws.svg';
import swift from '../../assets/tech/swift.svg';
import android from '../../assets/tech/android.svg';
import golang from '../../assets/tech/golang.svg';
import kotlin from '../../assets/tech/kotlin.svg';
import googleCloud from '../../assets/tech/google-cloud.svg';
import flutter from '../../assets/tech/flutter.svg';
import ios from '../../assets/tech/ios.svg';

const Offer = ({ pageRef }) => {
  const techs = [{
    title: "aws",
    logo: aws
  }, {
    title: "google cloud",
    logo: googleCloud
  }, {
    title: "ios",
    logo: ios
  }, {
    title: "android",
    logo: android
  }, {
    title: "flutter",
    logo: flutter
  }, {
    title: "python",
    logo: python
  }, {
    title: "go lang",
    logo: golang
  }, {
    title: "swift",
    logo: swift
  }, {
    title: "kotlin",
    logo: kotlin
  }]

  const textView = (
    <div className="info-block">
      <h1 className="title">What do we offer</h1>
      <span className="details">
        Solution architecture, project management, configuration management, mobile and cloud technologies - this is just a short glance at a list of practices that we offer and use. We think about business, taking care of the transparency and predictability of our work and looking for the best value for your money.
      </span>
      <ul className="technologies">
        {
          techs.map((item, index) => (
            <li key={index} className="inline-block">
              <img src={item.logo} alt={item.title}/>
            </li>
          ))
        }
      </ul>
    </div>
  );

  const imageView = (
    <div className="info-block">
      <img src={customers} alt='RockSoftware'/>
    </div>
  );

  return (
    <div ref={pageRef} className="position-relative customers-content">
      {
        isMobileOnly 
          ? <div>
              {textView}
              {imageView}
            </div>
          : <div className="info-block-container">
              {imageView}
              {textView}
            </div>
      }
    </div>
  )
}

export default Offer;
