import React, { useEffect, useState } from 'react'

import './header.css';
import logo from '../../assets/logo.png';

const Header = ({pages}) => {
  const scrollTo = (ref) => ref.current.scrollIntoView({behavior: 'smooth', block: 'end'})
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const nearestIndex = (currentPosition, sectionPositionArray, startIndex, endIndex) => {
      if (startIndex === endIndex) return startIndex;
      else if (startIndex === endIndex - 1) {
        if (
          Math.abs(sectionPositionArray[startIndex].ref.current.offsetTop - currentPosition) <
          Math.abs(sectionPositionArray[endIndex].ref.current.offsetTop - currentPosition)
        )
          return startIndex;
        else return endIndex;
      } else {
        var nextNearest = ~~((startIndex + endIndex) / 2);
        var a = Math.abs(sectionPositionArray[nextNearest].ref.current.offsetTop - currentPosition);
        var b = Math.abs(sectionPositionArray[nextNearest + 1].ref.current.offsetTop - currentPosition);
        if (a < b) {
          return nearestIndex(currentPosition, sectionPositionArray, startIndex, nextNearest);
        } else {
          return nearestIndex(currentPosition, sectionPositionArray, nextNearest, endIndex);
        }
      }
    };

    const handleScroll = (e) => {
      var index = nearestIndex(window.scrollY, pages, 0, pages.length - 1);
      setActiveIndex(index);
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <header className="container navigation fixed">
      <img src={logo} alt='RockSoftware' className="logo"/>
      <ul className="menu">
      {
        pages.map((item, index) => (
          <li key={index} className={activeIndex === index ? "inline menu-item-active" : "inline"}>
            <span className="menu-item" onClick={() => {scrollTo(item.ref)}}>
              {item.title}
            </span>
          </li>
        ))
      }
      <li className="inline btn-contact-container">
        <a href="mailto:info@rocksoftware.io" className="btn-contact">
          <span>Contact Us</span>
        </a>
      </li>
      </ul>
    </header>
  )
}

export default Header;