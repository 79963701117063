import { isMobileOnly } from 'react-device-detect';

import './about.css';
import home from '../../assets/home.png';

const About = ({ pageRef }) => {
  const pin = (<svg width="16" height="22.492" viewBox="0 0 16 22.492"><path id="ic_place_24px" d="M12,2A7,7,0,0,0,5,9c0,5.25,7,13,7,13s7-7.75,7-13A7,7,0,0,0,12,2Zm0,9.5A2.5,2.5,0,1,1,14.5,9,2.5,2.5,0,0,1,12,11.5Z" transform="translate(-4 -1)" fill="none" stroke="#000000" strokeWidth="2"></path></svg>);
  const mail = (<svg width="22" height="18" viewBox="0 0 22 18"><path id="ic_local_post_office_24px" d="M20,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,20,4Zm-8,7L4,6,3,5l9,6,9-6Z" transform="translate(-1 -3)" fill="none" stroke="#000000" strokeWidth="2"></path></svg>);

  return (
    <div ref={pageRef} className="position-relative about-content">
      <div className={isMobileOnly ? "" : "info-block-container"}>
        <div className="info-block">
          <h1 className="title">Company</h1>
          <ul className="details">
            <li>
              <span className="vertical-top">{pin}</span>
              <span className="vertical-top">&nbsp;Washington, USA</span>
            </li>
            <li>
              <span className="vertical-top">{mail}</span>
              <span className="vertical-top">&nbsp;info@rocksoftware.io</span>
            </li>
            <li className="copyright">
              © 2018-2024 RockSoftware LLC
            </li>
          </ul>
        </div>
        <div className="info-block">
          <img src={home} alt='RockSoftware'/>
        </div>
      </div>
    </div>
  )
}

export default About;
