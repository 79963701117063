import React, { useRef } from 'react'

import Header from '../header/Header';
import Home from '../home/Home';
import Offer from '../offer/Offer';
import About from '../about/About';

import './app.css';

function App() {
  const homeRef = useRef(null)
  const offerRef = useRef(null)
  const aboutRef = useRef(null)

  const pages = [{
    ref: homeRef,
    title: "Home"
  }, {
    ref: offerRef,
    title: "Offer"
  }, {
    ref: aboutRef,
    title: "Company"
  }]

  return (
    <div>
      <Header pages={pages}/>
      <div className="container page-container">
        <Home pageRef={homeRef}/>
        <Offer pageRef={offerRef}/>
        <About pageRef={aboutRef}/>
      </div>
    </div>
  );
}

export default App;
